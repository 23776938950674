import React, { useState, useRef, useEffect } from "react";
import "@wistia/wistia-player";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import PlayIconTesti from "../../assets/images/play-icon-testi.webp";

import CustomModal from "../Model/CustomModal";
const ResultsContent = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(
    location.search || "/Results?id=Videos"
  );

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      let currentSection = activeSection;
      const headerHeight =
        document.querySelector(".results-header").offsetHeight + 85; // 85px is the 'top' offset in CSS

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 + headerHeight &&
          rect.bottom >= window.innerHeight / 2 + headerHeight
        ) {
          currentSection = `/Results?id=${section.id}`;
        }
      });

      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
      }
      console.log("currentSection:", currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  useEffect(() => {
    if (location.search) {
      setActiveSection(location.search);
      const containerId = location.search.replace("?id=", "");
      const element = document.getElementById(containerId);
      if (element) {
        const headerHeight =
          document.querySelector(".results-header").offsetHeight + 85;
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY - headerHeight;
        window.scrollTo({ top: elementPosition, behavior: "smooth" });
      }
    } else {
      setActiveSection("/Results?id=Videos");
    }
  }, [location]);

  const getActiveClass = (searchParam) => {
    console.log(`Checking if ${activeSection} matches ${searchParam}`);
    return activeSection === searchParam ? "active" : "";
  };

  console.log("activeSection:", activeSection);

  const resultTab = ["Videos", "Skool", "Trustpilot", "Google"];
  const [selectedTab, setSelectedTab] = useState("Videos");

  const [openVideo, setOpenVideo] = useState(false);
  const [currentMediaId, setCurrentMediaId] = useState(null);

  const trustpilot = [
    {
      img: require("../../assets/images/trustpilot/1.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/2.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/3.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/4.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/5.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/6.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/7.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/8.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/9.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/10.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/11.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/12.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/13.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/14.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/15.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/16.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/17.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/18.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/19.webp"),
    },
    {
      img: require("../../assets/images/trustpilot/20.webp"),
    },
  ];

  const google = [
    {
      img: require("../../assets/images/google/4.webp"),
    },
    {
      img: require("../../assets/images/google/1.webp"),
    },
    {
      img: require("../../assets/images/google/2.webp"),
    },
    {
      img: require("../../assets/images/google/5.webp"),
    },
    {
      img: require("../../assets/images/google/6.webp"),
    },
    {
      img: require("../../assets/images/google/8.webp"),
    },
    {
      img: require("../../assets/images/google/7.webp"),
    },
    {
      img: require("../../assets/images/google/3.webp"),
    },
    {
      img: require("../../assets/images/google/9.webp"),
    },
  ];

  const skool = [
    {
      img: require("../../assets/images/skool/1.webp"),
    },
    {
      img: require("../../assets/images/skool/2.webp"),
    },
    {
      img: require("../../assets/images/skool/3.webp"),
    },
    {
      img: require("../../assets/images/skool/4.webp"),
    },
    {
      img: require("../../assets/images/skool/5.webp"),
    },
    {
      img: require("../../assets/images/skool/6.webp"),
    },
    {
      img: require("../../assets/images/skool/7.webp"),
    },
    {
      img: require("../../assets/images/skool/8.webp"),
    },
    {
      img: require("../../assets/images/skool/9.webp"),
    },
    {
      img: require("../../assets/images/skool/10.webp"),
    },
    {
      img: require("../../assets/images/skool/11.webp"),
    },
    {
      img: require("../../assets/images/skool/12.webp"),
    },
    {
      img: require("../../assets/images/skool/13.webp"),
    },
    {
      img: require("../../assets/images/skool/14.webp"),
    },
    {
      img: require("../../assets/images/skool/15.webp"),
    },
    {
      img: require("../../assets/images/skool/16.webp"),
    },
  ];

  const videos = [
    {
      thumbImg: require("../../assets/images/result-testi/1.SEA Thumbnail Ganesh.webp"),
      mediaId: "7u2k1g6s96",
      heading:
        "How Ganesh was able to make 26 Lakh in Profit with 1000 Rupees.",
      para: "Ganesh, a real estate business owner frustrated with unreliable agencies, self-learned digital marketing but struggled with the terminology. Joining Social Eagle Academy resolved his doubts in a month. He ran successful ads, earning 26 lakhs from one sale. He then launched his agency and became a digital strategist.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/2.SEA Thumbnail Richerd.webp"),
      mediaId: "abdz3imvg1",
      heading:
        "Richerd Made over 23 Lakh and also increased this Team Size by 3X.",
      para: "Richard, a digital marketer and business owner, faced financial struggles and lost confidence. Joining Social Eagle’s program connected him with supportive peers, boosting his motivation. He earned over 20 lakhs and won the Eagle Award at the TN Digital Summit.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/3.SEA Thumbnail Maheswari.webp"),
      mediaId: "2ofw3e9nox",
      heading:
        "How Maheswari Made Over 20 Lakhs in Revenue by Leveraging Digital Marketing in 3 Months.",
      para: "Maheswari runs Osai Media, a media company. Despite having her own agency, she wanted to upskill and help her clients transition to digital marketing. After joining Social Eagle’s Mentorship Program, she started upselling digital services and onboarding new clients. Using her new skills in other businesses, she comfortably made over 16 lakhs in 3 months.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/4.SEA Thumbnail Kamalakannan.webp"),
      mediaId: "rsdlzuxwhn",
      heading:
        "How Kamal turned into an Entrepreneur from an Employee while making more than 20 Lakhs in revenue.",
      para: "Kamal was a Corporate Employee and was helping his wife’s small business. He wanted to learn Digital Marketing to help his wife and in the process he started his own agency. He built Social Guru and now it is one of the renowned agencies in Dindigul, Tamil Nadu.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/5.SEA Thumbnail Mark Marimuthu.webp"),
      mediaId: "pyt49lf1lg",
      heading:
        "How Mark Marimuthu Made Over 20 Lakhs by Integrating Digital Marketing into His Existing Business.",
      para: "Mark Marimuthu runs a traditional media business. He wanted to add digital marketing services, so he joined Social Eagle’s Mentorship Program. By upselling digital marketing to his clients and offering combined services, he earned over 20 lakhs in less than 5 months.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/6.SEA Thumbnail Ameenur.webp"),
      mediaId: "xagsewhadg",
      heading:
        "How Ameenur Made Over 20 Lakhs in Revenue from Part-time Work in His Business.",
      para: "Ameenur, a full-time employee in Germany, runs two part-time businesses. After struggling despite joining many courses, he joined Social Eagle’s Digital Entrepreneurship Program, found the missing link, and now earns 2-3 lakhs every two weeks through webinars.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/7.SEA Thumbnail Ramesh.webp"),
      mediaId: "onc6flwghu",
      heading:
        "How Ramesh was able to get 4 New Clients Every Month for his agency services.",
      para: "Ramesh quickly shifted from being a professor to owning an agency within just 40 days of starting to learn Digital Marketing. Mastering everything from strategy creation to high-ticket sales boosted his confidence, securing him four clients monthly. He's now expanding his team.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/8.SEA Thumbnail Mukesh.webp"),
      mediaId: "uj51xtbu0a",
      heading: "How Mukesh bought his first car in 90 daysThrough Freelancing.",
      para: " Having spent more than 11L rupees in various other courses, Mukesh was not able to find the depth of digital marketing until he found the right knowledge and mentors at Social Eagle",
    },
    {
      thumbImg: require("../../assets/images/result-testi/9.SEA Thumbnail Alex Vibin.webp"),
      mediaId: "rfd2t49pg0",
      heading:
        "How Alex Vibin Grew His E-Commerce Brand and Started Two Other Businesses, Earning Over 10 Lakhs",
      para: "Alex Vibin, founder of Noobie Kid, an e-commerce brand selling kids toys, struggled with consistent sales through organic content. After joining the Digital Entrepreneurship Program, he started running ads, doubling his sales predictably. He also started a digital marketing agency and a tourism company, leveraging digital marketing to succeed.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/10.SEA Thumbnail Navaneetha.webp"),
      mediaId: "x1ybdl8ij9",
      heading:
        "How Navaneetha made more than 10 Lakhs after a Comeback from Career Break!",
      para: "Navaneetha, a housewife living in Saudi Arabia, began learning digital marketing to do something after a Career Break. After a 30-day course, she started her own Marketing agency, helping brands build authority on Social Media. Now, she has made more than 10 lakhs and chooses her clients. Her Success lies in her Collaborating power with the talents inside and outside the community.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/11.SEA Thumbnail Ragothaman.webp"),
      mediaId: "hjworhiwhn",
      heading:
        "How Ragothaman made more than 10 Lakhs in Revenue for his Real Estate Business!.",
      para: "Ragothaman, who runs a real estate business in Chennai, was frustrated with not getting qualified leads despite paying for third-party platforms. He decided to learn digital marketing to generate his own leads. After completing the course, he successfully generated leads from his ads and earned over 10 lakhs in revenue. Now, he’s confident and is scaling his business.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/37.SEA Thumbnail Abirami.webp"),
      mediaId: "rr6nu1fqui",
      heading: "How Abirami Became an Entrepreneur instead of an Employee",
      para: " Abirami became a housewife after moving to Dubai with her husband, putting her career dreams on hold. With 5 years of SEO experience, she wanted to restart her career but lacked a clear path. She joined Social Eagle Academy’s Digital Entrepreneurship Course, initially planning to find a job afterward. However, within 30 days, she started her own business and is now making five figures in revenue.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/13.SEA Thumbnail Naveen.webp"),
      mediaId: "oq7cthiyw7",
      heading:
        "How Naveen was able to make More than 7 Lakhs as an agency owner after failing in his 3 other businesses.",
      para: "Naveen, a first-time entrepreneur and mechanical engineer, had several failed businesses before joining Social Eagle. Now, after completing the Digital Entrepreneurship Initiator Program, he successfully runs a five-figure agency alongside his engineering career.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/14.SEA Thumbnail Priya.webp"),
      mediaId: "4zd36w6ie3",
      heading:
        "How a Corporate Employee like Priya was able to make more  than 5 Lakh with her side business using Digital Marketing",
      para: "Priya, originally an employee, joined Social Eagle Academy to learn digital marketing and assist her company. Discovering her passion, she began side hustles alongside her job, earning over 5 lakhs in five months.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/15.SEA Thumbnail Jeyasudha.webp"),
      mediaId: "80z8jx9epp",
      heading: "How Jeyasudha was able to earn 6+ Lakhs as an housewife.",
      para: "Jeyasudha, a former employee on a career break post-marriage, sought work-from-home opportunities. Discovering Social Eagle Academy's Digital Entrepreneurship Initiator Course, she secured her first client in under 30 days and now serves over 5 clients, earning over 6 lakh in revenue within 3 months.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/16.SEA Thumbnail Anitha.webp"),
      mediaId: "lju3ufacoo",
      heading: "How Anitha Made Over 5 Lakhs with Digital Marketing",
      para: "Anitha, a housewife living in Dubai, began learning digital marketing to help her husband's business. After a 30-day course, she started her own content marketing agency, helping brands build authority on LinkedIn. Now, she earns over 5 lakhs and chooses her clients.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/17.SEA Thumbnail Sunitha.webp"),
      mediaId: "k7msiy3rrb",
      heading: "How Sunitha Made Over 5 Lakhs in 60 Days",
      para: "Sunitha, a PhD scholar and healthcare consultant, was about to join a year-long digital marketing course. Instead, she joined Social Eagle after learning about Dharaneetharan and his team. Implementing what she learned and earning additional certifications, she became a LinkedIn Personal Branding Expert. Serving clients, she made over 5 lakhs in less than 60 days.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/18.SEA Thumbnail Vinothini.webp"),
      mediaId: "d6jzrvsx48",
      heading:
        "How Vinothini was able to grow her offline Boutique’s sales by 3X during off-season.",
      para: "Vinothini runs a boutique for kids party wear online and offline, a unique niche. After taking multiple courses with mixed results, she found success with mentorship from Social Eagle Academy. Implementing her new knowledge, she received over 1000 inquiries in the first week and made sales exceeding 8 lakhs within 30 days.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/19.SEA Thumbnail Giriesh.webp"),
      mediaId: "u6nxrhdu7i",
      heading:
        "How Giriesh helped his client make over $3000 with an investment of 1000 rupees.",
      para: "Giriesh, being a digital marketer was able to narrow his interest into funnels and email marketing while working with his clients. Now he is helping his e-com clients make $600 - $1000. He got this direction after he found the right knowledge and mentors at Social Eagle.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/20.SEA Thumbnail Karthik Raja.webp"),
      mediaId: "dqjap5tt8n",
      heading:
        "How Karthik Raja Earned more than 7 Lakhs without any Tech Experience",
      para: " Karthik runs a small business in southern Tamil Nadu with no prior tech experience. After learning digital marketing from Social Eagle Academy, he successfully generated leads for his real estate business. Interestingly, he began offering lead generation services to other businesses, starting his own agency and earning over 7 lakhs.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/21.SEA Thumbnail Sanjay.webp"),
      mediaId: "4l6znlv60g",
      heading:
        "How Sanjay Earns an Extra 1 Lakh as a Freelancer While Working Full-Time",
      para: "Sanjay is a digital marketing executive. His full-time job doesn't pay enough. He joined to earn an extra 1 lakh rupees as a freelancer. By using the Community's support, he reached his goal in less than 20 days after joining the Digital Entrepreneurship Program.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/22.SEA Thumbnail Jamuna.webp"),
      mediaId: "ioem04k50a",
      heading:
        "How Jamuna is making a profit of over 1 Lakh rupees for her Gift Shop Business.",
      para: "Jamuna was struggling to find a way to promote her offline gift shop in online. She tried multiple ways which she found was not helpful until she joined the Digital Entrepreneurship Initiator Program at Social Eagle.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/23.SEA Thumbnail Muthu Kumar.webp"),
      mediaId: "asoipzigg3",
      heading:
        "How Muthu Kumar added Digital Marketing to His Consulting Business and Earns Over 1 Lakh Each",
      para: "MonthMuthu Kumar runs a manufacturing consulting business, training people on processes and sales. After mentorship from Social Eagle Academy, he added digital marketing services to his offerings. Now, he earns an extra 1 lakh every month alongside his consulting business.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/24.SEA Thumbnail Kannan.webp"),
      mediaId: "h16jza2k15",
      heading:
        "How Kannan Increased His Client Base by 55% in less than 20 Days and is earning 4L/month",
      para: "Kannan, founder of Growpix, a digital marketing agency, wanted to grow his business beyond 10 clients. After joining the Digital Entrepreneurship Program, he helped clients earn over 35 lakhs in 40 days. This success led to many referrals, increasing his client base by 55% in less than 20 days.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/25.SEA Thumbnail Santhosh.webp"),
      mediaId: "b1jfys9j0q",
      heading: "How Santhosh made 36 times Returns for his Business.",
      para: "Santhosh, who runs three different businesses, wanted to learn digital marketing to better understand his team's work. Interestingly, he now runs his own campaigns and manages his team more effectively. His efforts have resulted in a 36x return on investment for his businesses.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/26.SEA Thumbnail Rajeswari.webp"),
      mediaId: "al5dy58ox4",
      heading:
        "Rajeswari earned over 3.8 Lakhs and Recreated her Identity after a Career Break",
      para: "Rajeswari, a housewife and former HR professional, took a career break after marriage but wanted to become independent again. She joined Social Eagle’s Digital Marketing Course and began by training others in digital marketing. She then started helping small businesses increase visibility and profits through her digital marketing services.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/27.SEA Thumbnail Shamrin.webp"),
      mediaId: "jb1ka033to",
      heading:
        "How Shamrin was able to make more than 2.6 Lakhs in 2 months with Digital Marketing!",
      para: "Shamrin wanted to help her husband’s printing business, so she started learning digital marketing. After completing the course, she launched her own agency. One of her clients even bought her a complete computer setup, and she used her earnings to start a physical office. She has made over 2.6 lakhs through digital marketing.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/28.SEA Thumbnail Jahir.webp"),
      mediaId: "vnbqwt8bko",
      heading: "Jahir earned over 2.4 Lakhs as an Extra Income.",
      para: " Jahir, a public speaking trainer, wanted to learn digital marketing because his friends were talking about it. He chose Social Eagle for his learning journey. While studying, he secured three speaking assignments and now uses digital marketing to grow his training business. Additionally, he helps other businesses generate leads, earning an extra 60K per month and has made over 2.4 lakhs so far.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/29.SEA Thumbnail Senthil Kumar.webp"),
      mediaId: "1c27fxuqjx",
      heading:
        "Senthil Kumar made over 2.3 Lakhs by providing Digital Marketing Services",
      para: "Senthil Kumar wanted to learn digital marketing to grow his medical equipment business. After completing the course, he used digital marketing to expand his business and also started his own agency. Through his agency, he made over 2.3 lakhs and even secured a deal with a government agency.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/30.SEA Thumbnail Ram Kumar.webp"),
      mediaId: "yuonw7a0su",
      heading: "How Ram Kumar Landed Big Clients billing Over 2 Lakhs",
      para: "Ram Kumar wanted to leave his current job. After joining Social Eagle Academy’s Mentorship Program and applying what he learned, he secured a major client deal worth over 7 lakhs in less than 30 days. Now, he is happily working as a digital entrepreneur.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/31.SEA Thumbnail Ashwin Krishna.webp"),
      mediaId: "q1htm1eraw",
      heading:
        "Ashwin Krishna made more than 5X Return on Investment in 60 days",
      para: "Ashwin, who runs a trading academy, was already using digital marketing but wanted to scale more efficiently. After joining Social Eagle, he began generating high-quality leads and conversions for his academy. Now, he also works with leading content creators and educators in the region, helping them with lead generation and sales.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/32.SEA Thumbnail Pavithra.webp"),
      mediaId: "71nqsiljho",
      heading:
        "Pavithra made 2 times the returns through collaboration inside the community",
      para: "Pavithra, a housewife and graphic designer helping her father’s printing business, wanted to recreate her identity and make a career comeback. After trying various approaches without success, she began collaborating with the Social Eagle Academy community. In less than 6 months, she earned over twice her investment. Her grit and determination were key to her success.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/33.SEA Thumbnail Kishore.webp"),
      mediaId: "1padnfbixe",
      heading:
        "Kishore earned the Respect he always wanted from his loved ones by making 1L+ in 30 days",
      para: "Kishore, a graduate helping with his father’s business, felt unfulfilled and lacked respect at home and among friends. Determined to make a name for himself, he joined Social Eagle Academy’s Digital Marketing Course. In less than 30 days, he earned over 1 lakh rupees and gained the respect he had longed for from his loved ones.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/34.SEA Thumbnail Selva Mariammal.webp"),
      mediaId: "w4y0kj361h",
      heading: "Selva Mariammal Made over 1.5 Lakh while working from home.",
      para: "Selva Mariammal, a housewife, wanted to work from home while spending time with her kids. With a passion for design, she decided to learn digital design and joined Social Eagle. After completing the course, she interned with entrepreneurs from the community, gaining real-time, practical experience. She then landed an opportunity to work with a renowned brand, allowing her to work from home and still be with her kids. She has made over 1.5 lakhs so far while working from home.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/35.SEA Thumbnail Jessy Priya.webp"),
      mediaId: "fks3f7kx3x",
      heading:
        "Jessy Priya Became the Family’s Breadwinner after marriage while earning over 1 Lakh",
      para: "Jessy Priya, a housewife, became a digital marketer after joining Social Eagle’s course. She started small, collaborating within the community, and used a 'value first, get paid later' strategy. This approach helped her earn more than she had invested, and she has now become the primary breadwinner of her family while earning over 1 Lakhs in revenue.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/36.SEA Thumbnail Selva Basti.webp"),
      mediaId: "hbsubfob8q",
      heading:
        "How Selva Basti earned 4 times more than his Salary in 2 Months!",
      para: "Selva Basti, a salaried employee, joined Social Eagle’s Digital Marketing Course to learn a new skill. By the end of the course, he joined networking forums, began offering digital marketing training, and gained clients. In less than 2 months, he earned 4 times more than his previous salary.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/45.SEA Thumbnail Davibala.webp"),
      mediaId: "in56ak6aiq",
      heading: "How Devibala Turned High Clicks Into ₹15K Profit With Zero Experience",
      para: "Devibala, with zero experience in digital marketing, was frustrated with high clicks but low sales after launching her first Meta ad for a calendar manufacturer. Instead of giving up, she shifted to a lead generation strategy. The result? ₹15K earned and paid in full. With the right approach, she turned clicks into revenue and proved that experience isn’t a barrier to success.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/44.SEA Thumbnail Satish.webp"),
      mediaId: "phrg3mv8eu",
      heading: "How Sathish Made ₹2.5 Lakh in 15 Days With Zero Marketing Experience",
      para: "Sathish, from Pondicherry, had no prior digital marketing experience. But after joining Social Eagle’s Digital Entrepreneurship Program, everything changed. In just 15 days, he earned ₹2.5 Lakhs by applying the right strategies. Sathish’s journey shows how the right guidance can turn ambition into success, no matter your starting point.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/43.SEA Thumbnail Bhavana.webp"),
      mediaId: "43wcb5tigh",
      heading: "How Bhuvana, a Dietician, Earned 6 Figures in 6 Days Without Any Tech Skills",
      para: "Bhuvana, A dietician with no tech skills or marketing experience made over 6 figures in just 6 days after joining Social Eagle’s program. With the right strategies, she transformed her business, and now, she and her husband are reaping the rewards. The Social Eagle community and proven techniques were key to her incredible success.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/42.SEA Thumbnail Jahawar.webp"),
      mediaId: "9t9s972b4z",
      heading: "How Jawahar Earned $35,000 in 30 Days With Social Eagle’s Program",
      para: "Jawahar, a software developer, struggled with cheap courses that led nowhere. After joining Social Eagle’s program, he secured $35,000 in deals in just 30 days, with his client pipeline continuing to grow. He cracked the code to freelancing success, and now, he’s scaling up. Ready to elevate your freelancing career?",
    },
    {
      thumbImg: require("../../assets/images/result-testi/41.SEA Thumbnail AswinRaaja.webp"),
      mediaId: "7zw3xumu1j",
      heading: "How Aswin Made ₹1 Lakh in 30 Days and Built a Thriving Agency",
      para: "Aswin Raajan, stuck in a stagnant job, yearned to break free and build something of his own. After joining Social Eagle’s Digital Entrepreneurship Program, he mastered lead generation, high-converting ads, and deal-closing strategies. In just 30 days, he earned over ₹1 Lakh and now runs a thriving digital agency. Aswin isn’t just making a living—he’s building a legacy.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/40.SEA Thumbnail Nishanthan.webp"),
      mediaId: "9g8qkzzo9u",
      heading: "How Nishanthan Went From ₹20K/Month to ₹1 Lakh+ in 8 Months",
      para: "Nishanthan, stuck in a ₹20K/month job, joined Social Eagle’s Digital Entrepreneurship Program with high hopes but faced 8 months without clients. Determined not to give up, he persevered. Now, he earns ₹80K+ monthly, has made over ₹1 Lakh, and leads a team. His journey proves persistence pays off.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/39.SEA Thumbnail Rizwanna.webp"),
      mediaId: "thmzi9vuur",
      heading: "How Rizwana Went From Zero Tech Skills to ₹4 Lakh in One Hackathon",
      para: "Rizwana had no tech skills or marketing knowledge, just a desire to succeed. After joining Social Eagle’s Digital Entrepreneurship Program, she generated 6-figure revenue, earning ₹4 Lakhs in a single hackathon. Now, she’s unstoppable, transforming her family’s future and building her empire. It all began with one leap.",
    },
    {
      thumbImg: require("../../assets/images/result-testi/38.SEA Thumbnail Ajith.webp"),
      mediaId: "k3fdj83vt2",
      heading: "How Ajith earned 1 Lakh in 30 days using real strategies from Social Eagle.",
      para: "Ajith, from the media industry, dreamed of owning an agency and gaining freedom. After joining Social Eagle’s program, he earned over ₹1 Lakh in under 30 days. With actionable strategies, he turned ambition into success, proving dreams are achievable with the right approach.",
    }
  ];
  return (
    <div className="results-content  py-5">
      <div className="container">
        <div className="results-header flexRow justify-content-between mb-2">
          <h2
            className="section-heading "
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            Students Stories
          </h2>

          <div className="d-flex justify-content-end align-items-center gap-lg-4 gap-md-4 gap-3">
            <Link
              to="/Results?id=Videos"
              className={`result-tab line-anim-result ${getActiveClass(
                "/Results?id=Videos"
              )}`}
            >
              Videos &nbsp;&nbsp; /{" "}
            </Link>
            <Link
              to="/Results?id=Skool"
              className={`result-tab line-anim-result ${getActiveClass(
                "/Results?id=Skool"
              )}`}
            >
              Skool &nbsp;&nbsp; /
            </Link>
            <Link
              to="/Results?id=Trustpilot"
              className={`result-tab line-anim-result ${getActiveClass(
                "/Results?id=Trustpilot"
              )}`}
            >
              Trustpilot &nbsp;&nbsp; /
            </Link>
            <Link
              to="/Results?id=Google"
              className={`result-tab line-anim-result ${getActiveClass(
                "/Results?id=Google"
              )}`}
            >
              Google
            </Link>
          </div>
        </div>
        {/* {selectedTab === "Videos" && ( */}
        <div className="pt-3 section" id="Videos">
          <h5 className="contacth2  mt-2">Videos</h5>
          <div className="row">
            {videos.map((item, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  key={index}
                >
                  <div className="">
                    <div
                      className=""
                      onClick={() => {
                        setOpenVideo(true);
                        setCurrentMediaId(item.mediaId);
                      }}
                    >
                      <div className="position-relative">
                        <div>
                          <img loading="lazy"
                            src={item.thumbImg}
                            alt="Thumbnail"
                            className="stu-testi-course"
                          />
                          <div
                            style={{
                              position: "absolute",
                              bottom: "5%",
                              left: "3%",
                            }}
                          >
                            <img loading="lazy"
                              className="play-icon-video-testi pulse-button"
                              src={PlayIconTesti}
                              alt=""
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="students-testi-card-content">
                      <h5 className="students-testi-heading">{item.heading}</h5>
                      <p className="mb-0 students-testi-para">{item.para}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* )} */}
        {/* {selectedTab === "Skool" && ( */}
        <div className="pt-3 section" id="Skool">
          <h5 className="contacth2  mt-2">Skool</h5>
          <div className="row">
            {skool.map((item, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <div className="students-testi-card">
                    <img loading="lazy" className="skool-imgs" src={item.img} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* )} */}
        {/* {selectedTab === "Trustpilot" && ( */}
        <div className="pt-3 section" id="Trustpilot">
          <h5 className="contacth2  mt-2">Trustpilot</h5>
          <div className="row">
            {trustpilot.map((item, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <div className="students-testi-card">
                    <img loading="lazy" className="skool-imgs" src={item.img} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* )} */}
        {/* {selectedTab === "Google" && ( */}
        <div className="pt-3 section" id="Google">
          <h5 className="contacth2 mt-2">Google</h5>
          <div className="row">
            {google.map((item, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <div className="students-testi-card">
                    <img loading="lazy" className="skool-imgs" src={item.img} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* )} */}
      </div>
      <CustomModal
        open={openVideo}
        onClickOutside={() => {
          setOpenVideo(false);
          setCurrentMediaId(null);
        }}
      >
        <div className="modal-content contact-container m-auto">
          <div className="modal-body w-100 p-0 ">
            <div className="close-btn">
              <i
                className="fa-regular fa-circle-xmark"
                onClick={() => setOpenVideo(false)}
              ></i>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="">
                    <wistia-player
                      style={{
                        width: "100%",
                        height: 420,
                        borderRadius: 12,
                      }}
                      media-id={currentMediaId}
                      player-color="#D32B3A"
                    ></wistia-player>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default ResultsContent;
